<template>
  <div class="view-wrap">
    <el-form :model="searchForm" class="F">
      <el-form-item label="录单时间:" style="width: 300px">
        <el-date-picker
          v-model="searchForm.time"
          type="datetime"
          placeholder="选择日期时间"
          format="yyyy-MM-dd HH:mm:ss"
          value-format="yyyy-MM-dd HH:mm:ss"
        >
        </el-date-picker>
      </el-form-item>
      <el-form-item
        label="单号:"
        style="width: 260px; margin-right: 15px"
        label-width="60px"
      >
        <el-input
          type="text"
          placeholder=""
          v-model="searchForm.no"
          disabled
        ></el-input>
      </el-form-item>

      <el-form-item label="入库仓库:" style="width: 280px; margin-right: 15px">
        <el-select v-model="searchForm.wmsId" placeholder="" clearable>
          <el-option
            :label="item.name"
            :value="item.id"
            v-for="item in wmsList"
            :key="item.id"
          >
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item
        label="客户:"
        style="width: 280px; margin-right: 15px"
        label-width="60px"
      >
        <div style="position: relative" @click="customClick">
          <el-input v-model="checkCustomName"></el-input>
          <span
            style="position: absolute; right: 10px; top: -3px; cursor: pointer"
            >...</span
          >
        </div>
      </el-form-item>
      <el-form-item label="当前欠款:" style="width: 130px">
        <span style="color: #f00">{{
          payment ? (payment/100).toFixed(2) : "0.00"
        }}</span
        >元
      </el-form-item>
      <el-form-item label="销售员(制单人):" style="width: 330px">
        <el-select v-model="searchForm.handler" placeholder="" clearable>
          <el-option
            :label="item.name"
            :value="item.id"
            v-for="item in userCustomList"
            :key="item.id"
          >
          </el-option>
        </el-select>
      </el-form-item>
    </el-form>
    <el-table
      :data="productList"
      border
      style="width: 100%"
      :cell-class-name="tableRowClassName"
      @cell-click="handleRowClick"
      show-summary
      :summary-method="getSummaries"
    >
      <el-table-column label="序号" width="80" align="center">
        <template slot-scope="scope">
          {{ scope.$index + 1 }}
        </template>
      </el-table-column>
      <el-table-column label="操作" width="100" align="center">
        <template slot-scope="scope">
          <el-button type="text" size="mini" @click="insertClick(scope.row)"
            >插入</el-button
          >
          <el-button type="text" size="mini" @click="removeLine(scope.$index)"
            >删除</el-button
          >
        </template>
      </el-table-column>
      <el-table-column
        align="center"
        prop="name"
        label="商品名称"
        show-overflow-tooltip
      >
      </el-table-column>
      <el-table-column
        align="center"
        prop="code"
        label="商品编号"
        show-overflow-tooltip
      >
      </el-table-column>
      <el-table-column
        align="center"
        prop="name"
        label="单位"
        show-overflow-tooltip
      >
        <template slot-scope="scope">
          <div
            v-if="
              scope.row.index === clickRowIndex &&
              tabClickLabel == scope.column.label
            "
          >
            <el-select
              placeholder="请选择"
              v-model="scope.row.unitId"
              @change="unitChange(scope.row)"
            >
              <el-option
                v-for="item in unitData"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </div>
          <div
            v-else
            @click="unitClick(scope.row.unitList)"
            style="width: 100%; cursor: pointer"
          >
            {{ getUnitName(scope.row.unitList, scope.row.unitId) }}
          </div>
        </template>
      </el-table-column>
      <el-table-column
        align="center"
        prop="buyNum"
        label="数量"
        show-overflow-tooltip
        min-width="115px"
      >
        <template slot-scope="scope">
          <div
            v-if="
              scope.row.index === clickRowIndex &&
              tabClickLabel == scope.column.label
            "
          >
            <el-input-number
              size="mini"
              controls-position="right"
              v-model="buyNum"
              @change="buyNumChange(scope.$index)"
            ></el-input-number>
          </div>
          <span v-else>{{ scope.row.buyNum }}</span>
        </template>
      </el-table-column>
      <el-table-column
        align="center"
        prop="price"
        label="单价"
        show-overflow-tooltip
      >
        <template slot-scope="scope">
          <div
            v-if="
              scope.row.index === clickRowIndex &&
              tabClickLabel == scope.column.label
            "
            style="position: relative"
          >
            <el-input
              size="mini"
              controls-position="right"
              v-model="scope.row.price"
              @blur="inputBlur(scope.row)"
            ></el-input>
            <span
              style="position: absolute; top: 10%; right: 5px; cursor: pointer"
              @click="openPriceList(scope.row, scope.$index)"
              >···</span
            >
          </div>
          <span v-else>
            <span
              :class="
                scope.row.priceType == '零售价'
                  ? 'priceActive'
                  : scope.row.priceType == '最新采购价'
                  ? 'priceActive2'
                  : scope.row.priceType == '参考采购价'
                  ? 'priceActive2'
                  : scope.row.priceType == '最新售价'
                  ? 'priceActive2'
                  : scope.row.priceType == '批发价'
                  ? 'priceActive3'
                  : scope.row.priceType == '会员等级1'
                  ? 'priceActive4'
                  : scope.row.priceType == '会员等级2'
                  ? 'priceActive4'
                  : scope.row.priceType == '会员等级3'
                  ? 'priceActive4'
                  : scope.row.priceType == '会员等级4'
                  ? 'priceActive4'
                  : scope.row.priceType == '会员等级5'
                  ? 'priceActive4'
                  : ''
              "
              >{{ scope.row.priceType }}</span
            >
            {{ scope.row.price ? scope.row.price.toFixed(2) : "0.00" }}</span
          >
        </template>
      </el-table-column>
      <el-table-column
        align="center"
        label="金额"
        show-overflow-tooltip
        prop="jine"
      >
        <template slot-scope="scope">
          {{ scope.row.jine }}
        </template>
      </el-table-column>
      <el-table-column
        align="center"
        prop="discount"
        label="折扣率(%)"
        show-overflow-tooltip
        min-width="115px"
      >
        <template slot-scope="scope">
          <div
            v-if="
              scope.row.index === clickRowIndex &&
              tabClickLabel == scope.column.label
            "
          >
            <el-input-number
              size="mini"
              controls-position="right"
              v-model="discount"
              @change="discountChange(scope.$index)"
            ></el-input-number>
          </div>
          <span v-else>{{
            scope.row.discount ? scope.row.discount + "%" : "0%"
          }}</span>
        </template>
      </el-table-column>
      <el-table-column
        align="center"
        label="折后单价"
        show-overflow-tooltip
        prop="zhehouPrice"
      >
        <template slot-scope="scope">
          {{ scope.row.zhehouPrice }}
        </template>
      </el-table-column>
      <el-table-column
        align="center"
        label="折后金额"
        show-overflow-tooltip
        prop="zhehoujine"
      >
        <template slot-scope="scope">
          {{ scope.row.zhehoujine }}
        </template>
      </el-table-column>
      <el-table-column
        align="center"
        label="折扣优惠"
        show-overflow-tooltip
        prop="discountYouhui"
      >
        <template slot-scope="scope">
          {{ scope.row.discountYouhui }}
        </template>
      </el-table-column>
      <el-table-column align="center" prop="isGifts" label="赠品">
        <template slot-scope="scope">
          <input type="checkbox" v-model="scope.row.isGifts" />
        </template>
      </el-table-column>
      <el-table-column
        align="center"
        prop="remark"
        label="备注"
        show-overflow-tooltip
      >
        <template slot-scope="scope">
          <div
            v-if="
              scope.row.index === clickRowIndex &&
              tabClickLabel == scope.column.label
            "
          >
            <el-input
              size="small"
              v-model="scope.row.remark"
              @blur="inputBlur(scope.row)"
            ></el-input>
          </div>
          <span v-else>{{ scope.row.remark }}</span>
        </template>
      </el-table-column>
    </el-table>
    <!-- 价格参照dialog -->
    <el-dialog title="价格参照" :visible.sync="dialogVisible" width="40%">
      <div slot="title" class="dialog-title">
        <span style="display: inline-block; margin-right: 6px">价格参照</span>
        <el-tooltip
          class="item"
          effect="light"
          content="您可以选择任意参照价格，直接设置为单价"
          placement="right"
        >
          <i class="el-icon-question"></i>
        </el-tooltip>
      </div>
      <div>
        <div class="proName">名称：名称</div>
        <div class="proUnit">单位：箱</div>
        <div class="FSB">
          <div
            class="priceItem"
            v-for="(item, index) in priceList"
            :key="index"
            @click="priceClick(item)"
          >
            <div class="price">{{ item.value.toFixed(2) }}</div>
            <div class="priceType">{{ item.label }}</div>
          </div>
        </div>
      </div>
      <div class="F" style="margin-top: 15px">
        <div class="line"></div>
        <div>会员价</div>
      </div>
      <div class="FSB">
        <div
          class="priceItem"
          v-for="(item, index) in vipList"
          :key="index"
          @click="vipPriceClick(item)"
        >
          <div class="price">{{ item.price.toFixed(2) }}</div>
          <div class="priceType">{{ item.label }}</div>
        </div>
      </div>
    </el-dialog>
    <SelectCustom :info="cusInfo" @SelectCustom="SelectCustom"></SelectCustom>
    <!-- 选择商品 -->
    <miaDialog width="70%" ref="SelectGoods" v-if="Visible">
      <div slot="content">
        <SelectGoods @close="Visible = false" @SelectGoods="AddGoods" />
      </div>
    </miaDialog>
    <div class="foorter FSB">
      <div></div>
      <div style="position: fixed; bottom: 10px; right: 50px">
        <el-button plain @click="backList" type="primary">返回列表</el-button>
        <el-button @click="saveData" type="primary">提交</el-button>
      </div>
    </div>
  </div>
</template>

<script>
import { GetAccountList } from "@/api/account";
import { getProductParamList, getPriceReference } from "@/api/pesticideProduct";
import { GetWmsList } from "@/api/warehouse";
import { mapState } from "vuex";
import SelectCustom from "@/components/SelectCustom";
import SelectGoods from "@/components/pustorageTable/SelectGoods";
import moment from "moment";
import { getRefCode, setWebOrderRef } from "@/api/sale";
import { getUserCustom } from "@/api/common";
export default {
  computed: {
    ...mapState("user", ["userName", "name"]),
  },
  components: {
    SelectCustom,
    SelectGoods,
  },
  data() {
    return {
      accountId: "",
      discount: 100,
      buyNum: 1,
      productData: [],
      Visible: false,
      priceDialogId: "",
      priceDialogIndex: "",
      priceList: [],
      vipList: [], //会员价列表
      clickRowIndex: null,
      tabClickLabel: "",
      userCustomList: [],
      payment: 0,
      wmsList: [],
      checkCustomName: [],
      userCustomList: [],
      cusInfo: {
        isShow: false,
      },
      searchForm: {
        time: "",
        customerIds: "",
        no: "",
        handler: "",
        wmsId: "",
      },
      productList: [
        {
          name: "",
          code: "",
          unit: "",
          buyNum: "",
          price: "",
          jine: "",
          discount: "",
          zhehouPrice: "",
          zhehoujine: "",
          discountYouhui: "",
          isGifts: "",
          remark: "",
          unitList: [],
        },
      ],
      clickRowIndex: null,
      tabClickLabel: "",
      dialogVisible: false,
    };
  },
  created() {
    this.searchForm.time = moment().format("YYYY-MM-DD HH:mm:ss");
    this.getRefCode();
    this.getUserCustom();
    this.GetWmsList();
    this.GetAccountList();
  },
  methods: {
    getSummaries(param) {
      const { columns, data } = param;
      const sums = [];
      columns.forEach((column, index) => {
        if (index === 0) {
          sums[index] = "合计";
          return;
        }
        //calcProp 为需要计算的列的prop值的集合
        if (column.property === "buyNum") {
          const values = data.map((item) => item["buyNum"]);
          sums[index] = values.reduce((prev, curr) => {
            if (curr) {
              return prev + curr;
            } else {
              return prev;
            }
          });
        }
        if (
          column.property === "price" ||
          column.property === "jine" ||
          column.property === "discountYouhui" ||
          column.property === "zhehoujine"
        ) {
          const values = data.map((item) => Number(item[column.property]));
          let num = 0;
          values.forEach((v) => {
            if (v) {
              return (num += parseFloat(v));
            } else {
              return (num += 0);
            }
          });
          sums[index] = this.backPrice((num.toFixed(3) * 1000) / 1000);
        }
      });
      return sums;
    },
    backPrice(val) {
      if (val == null || val == "NaN") {
        return "";
      }
      let num = val;
      num += "";
      num = num.replace(/[^0-9|\.]/g, "");

      if (/^0+/) num = num.replace(/^0+/, "");
      if (!/\./.test(num)) num += ".00";
      if (/^\./.test(num)) num = "0" + num;
      num += "00";
      num = num.match(/\d+\.\d{2}/)[0];
      return num;
    },
    // 获取账户
    async GetAccountList() {
      let res = await GetAccountList();
      if (res.code == 1) {
        this.accountId = res.data.records[0].id;
      }
    },
    async saveData() {
      if (!this.searchForm.customerIds) {
        this.$message({
          type: "warning",
          message: "请选择客户",
        });
        return;
      }
      let price = 0;
      let preferentialPrice = 0;
      let handlePrice = 0;
      let thisPayment = 0;
      console.log(this.productList);
      this.productList.forEach((item) => {
        price += Number(item.jine * 100);
        preferentialPrice += Number(item.discountYouhui * 100);
        handlePrice += Number(item.zhehoujine * 100);
        thisPayment += Number(item.zhehoujine * 100);
      });
      this.productList = this.productList.map((item) => {
        return {
          productId: item.id,
          num: item.buyNum,
          unitId: item.unitId,
          price: item.price * 100,
          discount: item.discount,
          discountPrice: item.price * item.discount,
          isGifts: item.isGifts,
          remark: item.remark,
        };
      });
      let params = {
        customerId: this.searchForm.customerIds,
        status: 0,
        wmsId: this.searchForm.wmsId,
        handler: this.searchForm.handler,
        entryDate: this.searchForm.time,
        no: this.searchForm.no,
        price,
        preferentialPrice,
        handlePrice,
        thisPayment,
        accountId: this.accountId,
        productList: this.productList,
      };
      let res = await setWebOrderRef(params);
      if (res.code == 1) {
        this.productList = [];
        this.discount = 100;
        this.buyNum = 1;
        this.$message({
          type: "success",
          message: res.msg,
        });
        this.$router.push({
          path: "/sale/retailReturnList/saleReturn",
        });
      }
    },
    discountChange(index) {
      this.productList[index].discount = this.discount;
      this.sumPrice();
    },
    buyNumChange(index) {
      this.productList[index].buyNum = this.buyNum;
      this.productList = JSON.parse(JSON.stringify(this.productList))
      this.sumPrice();
    },
    // 获取商品列表
    async getProductParamList() {
      let params = {
        wmsId: this.searchForm.wmsId,
      };
      let res = await getProductParamList(params);
      if (res.code == 1) {
        this.productData = res.data.records;
      }
    },
    AddGoods(data) {
      if (this.productList && this.productList.length == 1) {
        this.productList = data;
        this.productList.forEach((item) => {
          item.buyNum = 1;
          item.isGifts = 0;
          item.remark = "";
          item.discount = 100;
          item.price = item.unitList[0].price;
          item.unitId = item.unitList[0].id;
          item.priceType = "零售价";
        });
      } else {
        data.forEach((item) => {
          item.buyNum = 1;
          item.isGifts = 0;
          item.remark = "";
          item.discount = 100;
          item.price = item.unitList[0].price;
          item.unitId = item.unitList[0].id;
          item.priceType = "零售价";
        });
        this.productList = this.productList.concat(data);
        this.productList.forEach((item, index) => {
          if (!item.name) {
            this.productList.splice(index, 1);
          }
        });
      }
      this.sumPrice();
      this.Visible = false;
      // console.log(this.productList, this.productData);
    },
    sumPrice() {
      this.productList.forEach((item) => {
        item.jine = (item.buyNum * item.price).toFixed(2);
        item.zhehouPrice = ((item.discount * item.price) / 100).toFixed(2);
        item.zhehoujine = (
          (item.buyNum * item.price * item.discount) /
          100
        ).toFixed(2);
        item.discountYouhui = (
          item.buyNum * item.price -
          (item.buyNum * item.price * item.discount) / 100
        ).toFixed(2);
      });
    },
    insertClick() {
      this.productList.push({
        name: "",
        code: "",
        unit: "",
        buyNum: "",
        price: "",
        jine: "",
        discount: "",
        zhehouPrice: "",
        zhehoujine: "",
        discountYouhui: "",
        isGifts: "",
        remark: "",
        unitList: [],
      });
    },
    removeLine(index) {
      this.productList.splice(index, 1);
    },
    vipPriceClick(item) {
      this.productList[this.priceDialogIndex].price = item.price;
      this.productList[this.priceDialogIndex].priceType = item.label;
      this.dialogVisible = false;
      this.$set(this.productList);
      this.inputBlur();
    },
    //价格点击
    priceClick(item) {
      console.log(item, this.priceDialogId, this.priceDialogIndex);
      this.productList[this.priceDialogIndex].price = item.value;
      this.productList[this.priceDialogIndex].priceType = item.label;
      this.dialogVisible = false;
      this.$set(this.productList);
      this.inputBlur();
    },
    getUnitName(unitList, unitId) {
      let name = "";
      unitList.forEach((item) => {
        if (item.id == unitId) {
          name = item.name;
        }
      });
      return name;
    },
    unitChange(val) {
      val.unitList.forEach((item) => {
        if (item.id == val.unitId) {
          val.price = item.price;
        }
      });
      this.$set(this.productList);
      this.inputBlur();
      // this.sumPrice();
    },
    // 获取商品单位
    unitClick(val) {
      let arr = [];
      val.forEach((item) => {
        let obj = {
          value: "",
          label: "",
        };
        obj.value = item.id;
        obj.label = item.name;
        arr.push(obj);
      });
      this.unitData = arr;
    },
    // 查询价格参照
    async getPriceReference(productId, unitId) {
      this.priceList = [];
      let obj = {
        value: "",
        label: "",
      };
      let obj2 = {
        value: "",
        label: "",
      };
      let params = {
        productId,
        unitId,
      };
      let res = await getPriceReference(params);
      if (res.code == 1 && res.data) {
        if (res.data.newPurchasePrice) {
          obj.value = res.data.newPurchasePrice/100; //最新采购价
          obj.label = "最新采购价";
          this.priceList.push(obj);
        }
        if (res.data.newOrderPrice) {
          obj2.value = res.data.newOrderPrice/100; //最新售价
          obj2.label = "最新售价";
          this.priceList.push(obj2);
        }
      }
    },
    openPriceList(row, index) {
      let obj = {
        value: "",
        label: "采购价",
      };
      let obj2 = {
        value: "",
        label: "零售价",
      };
      let obj3 = {
        value: "",
        label: "批发价",
      };
      this.getPriceReference(row.id, row.unitId);
      row.unitList.forEach((item) => {
        if (item.id == row.unitId) {
          this.vipList = item.unitTemplateList;
          obj.value = item.purchasePrice;
          obj2.value = item.price;
          obj3.value = item.batchPrice;
          this.priceList.push(obj);
          this.priceList.push(obj2);
          this.priceList.push(obj3);
        }
      });
      this.vipList[0].label = "会员等级1";
      this.vipList[1].label = "会员等级2";
      this.vipList[2].label = "会员等级3";
      this.vipList[3].label = "会员等级4";
      this.vipList[4].label = "会员等级5";
      this.priceDialogId = row.id;
      this.priceDialogIndex = index;
      this.dialogVisible = true;
    },
    tableRowClassName({ row, rowIndex, columnIndex, column }) {
      // 把每一行的索引放进row
      row.index = rowIndex;
    },
    handleRowClick(row, column) {
      this.clickRowIndex = row.index;
      this.tabClickLabel = column.label;
      if (this.tabClickLabel == "商品名称") {
        this.Visible = true;
        this.$nextTick(() => {
          this.$refs.SelectGoods.init("选择商品");
        });
      }
    },
    inputBlur(row) {
      this.clickRowIndex = null;
      this.tabClickLabel = "";
      this.sumPrice();
    },
    //获取仓库
    async GetWmsList() {
      let res = await GetWmsList();
      if (res.code == 1) {
        this.wmsList = res.data.records;
        this.wmsList.forEach((item) => {
          if (item.name == "默认仓库") {
            this.searchForm.wmsId = item.id;
          }
        });
        this.getProductParamList();
      }
    },
    //获取经手人
    async getUserCustom() {
      console.log(this.name);
      let res = await getUserCustom();
      if (res.code == 1) {
        this.userCustomList = res.data.records;
        this.userCustomList.forEach((item) => {
          if (this.name == item.name) {
            this.searchForm.handler = item.id;
          }
        });
      }
    },
    async getRefCode() {
      let params = {
        date: this.searchForm.time,
      };
      let res = await getRefCode(params);
      if (res.code == 1) {
        this.searchForm.no = res.data;
      }
    },
    SelectCustom(list) {
      this.payment = list[0].payment;
      this.checkCustomName = list.map((item) => item.name).join(",");
      this.searchForm.customerIds = list.map((item) => item.id).join(",");
    },
    customClick() {
      this.cusInfo.isShow = true;
    },
    inputBlur(row) {
      this.clickRowIndex = null;
      this.tabClickLabel = "";
      this.productList = JSON.parse(JSON.stringify(this.productList));
      this.sumPrice();
    },
    tableRowClassName({ row, rowIndex, columnIndex, column }) {
      // 把每一行的索引放进row
      row.index = rowIndex;
    },
    // 控制input显示 row 当前行 column 当前列
    tabClick(row, column, cell, event) {
      this.clickRowIndex = row.index;
      this.tabClickLabel = column.label;
    },
    topPriceClick(index) {
      console.log(index);
    },
    backList() {
      this.$router.push({
        path: "/sale/retailReturnList/saleReturn",
      });
    },
  },
};
</script>

<style scoped>
.priceActive {
  color: #76d3fa;
}
.priceActive2 {
  color: #c295ff;
}
.priceActive3 {
  color: #f7ae4f;
}
.priceActive4 {
  color: #31b4a8;
}
.F {
  display: flex;
}
.FSB {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
::v-deep .el-dialog__body {
  padding: 15px 20px !important;
  color: #606266;
  font-size: 14px;
  word-break: break-all;
}
.proName {
  line-height: 30px;
}
.proUnit {
  line-height: 30px;
}
.priceItem {
  width: 23%;
  height: 60px;
  border: 1px solid #facd91;
  text-align: center;
  line-height: 20px;
  margin-top: 15px;
  cursor: pointer;
}
.price {
  margin-top: 13px;
  font-style: 18px;
  font-weight: bold;
}
.priceType {
  font-size: 12px;
  color: #999;
}
.line {
  width: 4px;
  height: 20px;
  background-color: #35c093;
  margin-right: 10px;
}
</style>