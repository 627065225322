<template>
  <div>
    <el-dialog title="选择客户" :visible.sync="info.isShow" width="60%">
      <div class="treeContent">
        <div class="left">
          <el-tree
            style="margin: 10px"
            node-key="id"
            :data="GoodsTypeList"
            :props="defaultProps"
            default-expand-all
            ref="tree"
            :highlight-current="true"
            @node-click="clickNode"
          >
          </el-tree>
        </div>
        <div class="right">
          <el-row style="margin: 10px; width: 30%">
            <el-input
              v-model="searcForm.param"
              placeholder="搜索商品名称/编号/条码"
            >
              <i
                slot="prefix"
                @click="getCustomer()"
                class="el-input__icon el-icon-search"
              ></i>
            </el-input>
          </el-row>
          <div>
            <el-table
              height="45vh"
              v-loading="loading"
              style="margin: 0px 10px; width: 97%"
              border
              ref="multipleTable"
              :data="customerList"
              tooltip-effect="dark"
              @selection-change="handleSelectionChange"
              row-key="id"
            >
              <el-table-column
                align="center"
                :reserve-selection="true"
                type="selection"
                width="55"
              ></el-table-column>
              <el-table-column align="center" label="客户编码" prop="code"
                ><template slot-scope="scope"
                  ><div style="text-align: left">
                    {{ scope.row.code ? scope.row.code : "-" }}
                  </div></template
                ></el-table-column
              >
              <el-table-column align="center" label="客户名称" prop="name"
                ><template slot-scope="scope"
                  ><div style="text-align: left">
                    {{ scope.row.name ? scope.row.name : "-" }}
                  </div></template
                ></el-table-column
              >
              <el-table-column align="center" label="类型" prop="typeId"
                ><template slot-scope="scope"
                  ><div style="text-align: left">
                    {{ scope.row.typeId ? scope.row.typeId : "-" }}
                  </div></template
                ></el-table-column
              >
              <el-table-column align="center" label="联系人" prop="contact"
                ><template slot-scope="scope"
                  ><div style="text-align: left">
                    {{ scope.row.contact ? scope.row.contact : "-" }}
                  </div></template
                ></el-table-column
              >
              <el-table-column align="center" label="手机号码" prop="phone"
                ><template slot-scope="scope"
                  ><div style="text-align: left">
                    {{ scope.row.phone ? scope.row.phone : "-" }}
                  </div></template
                ></el-table-column
              >
              <el-table-column align="center" label="身份证号码" prop="">
                <template>
                  <div style="text-align: left">-</div>
                </template>
              </el-table-column>
            </el-table>
            <el-pagination
              class="pagination"
              @size-change="changePageSize"
              @current-change="changeCurrentPage"
              :current-page="searcForm.pageNum"
              :page-size="searcForm.pageSize"
              layout="total, sizes, prev, pager, next, jumper"
              :total="total"
            >
            </el-pagination>
          </div>
        </div>
      </div>

      <span slot="footer" class="dialog-footer">
        <el-button @click="info.isShow = false">取 消</el-button>
        <el-button type="primary" @click="submitData">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { GetCustomerTypeList, GetCustomerList } from "@/api/customer";
export default {
  props: {
    info: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      customerList: [],
      loading: false,
      GoodsTypeList: [],
      defaultProps: {
        children: "list",
        label: "name",
      },
      searcForm: {
        pageNum: 1,
        pageSize: 10,
        param: "",
        typeId: null,
      },
      total: 0,
    };
  },
  created() {
    this.getGoodsType();
    this.getCustomer();
  },
  methods: {
    // 获取客户详情
    async getCustomer() {
      let params = this.searcForm;
      let res = await GetCustomerList(params);
      if (res.code == 1) {
        this.customerList = res.data.records;
       
        this.total = res.data.total;
      }
    },
    changePageSize(val) {
      this.searcForm.pageSize = val;
      this.searcForm.pageNum = 1;
      // this.getData();
    },
    changeCurrentPage(val) {
      this.searcForm.pageNum = val;
      // this.getData();
    },
    setSelection(customId){
      
        if(customId){
          this.$nextTick(()=>{
             
          const selectes = this.customerList.filter(v=>v.id === customId);
          this.$refs.multipleTable.clearSelection();
          this.$refs.multipleTable.toggleRowSelection(selectes[0]);
         });
          
        }
      
      
    },
    handleSelectionChange(val) {
      if (val && val.length > 1) {
       this.$refs.multipleTable.clearSelection();
       this.$refs.multipleTable.toggleRowSelection(val[val.length-1]);
      
      //   this.$message({
      //     type: "warning",
      //     message: "只能选择一个客户",
      //   });
      //   return;
      }
      if (val && val.length <= 1) {
        this.multipleSelection = val;
      }
    },
    // 客户分类
    getGoodsType() {
      GetCustomerTypeList({}).then((res) => {
        if (res.code === 1) {
          this.GoodsTypeList = res.data.records;
          this.GoodsTypeList.unshift({ id: null, name: "全部" });
        } else {
          this.GoodsTypeList = [];
        }
      });
    },
    clickNode(data, node) {
      if (data.id) {
        this.searcForm.typeId = data.id;
        this.getCustomer();
      } else {
        this.searcForm.typeId = null;
        this.getCustomer();
      }
    },
    submitData() {
      if(this.multipleSelection.length===0){
        this.$message.warning("请选择客户");
      }
      this.$emit("SelectCustom", this.multipleSelection);
      this.info.isShow = false;
    },
  },
};
</script>

<style lang="scss" scoped>
/deep/ .el-tree-node:focus > .el-tree-node__content {
  background-color: #f0f7ff !important;
}
.treeContent {
  width: 100%;
  height: 60vh;
  display: flex;
  justify-content: start;
  .left,
  .right {
    height: 100%;
    border: 1px solid gray;
  }
  .left {
    width: 20%;
    border-right: 0px;
    overflow: auto;
  }
  .right {
    width: 80%;
    position: relative;
    .pagination {
      width: 100%;
      text-align: center;
      margin-top: 10px;
      position: absolute;
      bottom: 10px;
    }
  }
}
</style>